<template>
  <div class="shubox">
    <!-- 热力图 -->
    <div class="echatitem" ref="rebox" v-show="rebox">
      <div class="echatit">
        <img
          class="close"
          src="../assets/img/cd/fanhui.png"
          alt=""
          @click="enlarge"
          v-show="fangda"
        />
        <img
          class="close"
          @click.stop="guanbi"
          src="../assets/img/cd/guan.png"
          alt=""
          v-show="rebtn"
        />
      </div>
       <iframe
        :src="hotsrc"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
      <!-- <div class="rebox">
        <div class="reitem">
          <button class="rebtn" @click="heatmap.show()">显示热力图</button>
        </div>
        <div class="reitem">
          <button class="rebtn" @click="heatmap.hide()">关闭热力图</button>
        </div>
      </div> -->
    </div>
    <!-- 技师排行榜 -->
    <div class="echatitem" ref="rebox2" v-show="jishibox">
      <div class="echatit">
        <img
          class="close"
          src="../assets/img/cd/fanhui.png"
          alt=""
          @click="enlarge2"
          v-show="fangda"
        />
        <img
          class="close"
          @click.stop="guanbi2"
          src="../assets/img/cd/guan.png"
          alt=""
          v-show="rebtn"
        />
      </div>
      <iframe
        :src="techsrc"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
    <!-- 客服效率榜 -->
    <div class="echatitem" ref="rebox3" v-show="kebox">
      <div class="echatit">
        <img
          class="close"
          src="../assets/img/cd/fanhui.png"
          alt=""
          @click="enlarge3"
          v-show="fangda"
        />
        <img
          class="close"
          @click.stop="guanbi3"
          src="../assets/img/cd/guan.png"
          alt=""
          v-show="rebtn"
        />
      </div>
      <iframe
        :src="kefusrc"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
    <!-- 业务产值榜 -->
    <div class="echatitem" ref="rebox4" v-show="yebox">
      <div class="echatit">
        <img
          class="close"
          src="../assets/img/cd/fanhui.png"
          alt=""
          @click="enlarge4"
          v-show="fangda"
        />
        <img
          class="close"
          @click.stop="guanbi4"
          src="../assets/img/cd/guan.png"
          alt=""
          v-show="rebtn"
        />
      </div>
      <iframe
        :src="salesrc"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      map: null,
      heatmap: null,
      rebox: true,
      jishibox: true,
      kebox: true,
      yebox: true,
      rebtn: false,
      token: Cookies.get("ntwl-token"),
      techsrc: "",
      salesrc: "",
      kefusrc: "",
      hotsrc:"",

      fangda: true,
    };
  },
  created() {
    this.techsrc =
      "https://www-test.nituowola.com/site/tech-rank?token=" + this.token;
    this.salesrc =
      "https://www-test.nituowola.com/site/sale-rank?token=" + this.token;
    this.kefusrc =
      "https://www-test.nituowola.com/site/kefu-rank?token=" + this.token;
    this.hotsrc =
      "https://www-test.nituowola.com/site/hotline?token=" + this.token;
  },
  mounted() {
    // 延迟加载，防止出现AMap not defined
    setTimeout(() => {
      this.initMap();
      this.createHeatMap();
    }, 1000);
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  },
  methods: {
    // 热力图
    enlarge() {
      this.$refs.rebox.classList.add("reechatitem");
      this.jishibox = false;
      this.kebox = false;
      this.yebox = false;
      this.rebtn = true;
      this.fangda = false;
    },
    guanbi() {
      console.log("热力图", this.$refs.rebox2);
      this.$refs.rebox.classList.remove("reechatitem");
      this.jishibox = true;
      this.kebox = true;
      this.yebox = true;
      this.rebtn = false;
      this.fangda = true;
    },
    // 技师排行榜
    enlarge2() {
      this.$refs.rebox2.classList.add("reechatitem");
      this.rebox = false;
      this.kebox = false;
      this.yebox = false;
      this.rebtn = true;
      this.fangda = false;
    },
    guanbi2() {
      console.log("技师排行榜", this.$refs.rebox2);
      this.$refs.rebox2.classList.remove("reechatitem");
      this.rebox = true;
      this.kebox = true;
      this.yebox = true;
      this.rebtn = false;
      this.fangda = true;
    },
    // 客服效率榜
    enlarge3() {
      this.$refs.rebox3.classList.add("reechatitem");
      this.rebox = false;
      this.jishibox = false;
      this.yebox = false;
      this.rebtn = true;
      this.fangda = false;
    },
    guanbi3() {
      //console.log("客服效率榜", this.$refs.rebox3);
      this.$refs.rebox3.classList.remove("reechatitem");
      this.rebox = true;
      this.jishibox = true;
      this.yebox = true;
      this.rebtn = false;
      this.fangda = true;
    },
    // 业务产值榜
    enlarge4() {
      this.$refs.rebox4.classList.add("reechatitem");
      this.rebox = false;
      this.jishibox = false;
      this.kebox = false;
      this.rebtn = true;
      this.fangda = false;
    },
    guanbi4() {
      console.log("业务产值榜", this.$refs.rebox4);
      this.$refs.rebox4.classList.remove("reechatitem");
      this.rebox = true;
      this.jishibox = true;
      this.kebox = true;
      this.rebtn = false;
      this.fangda = true;
    },

    initMap() {
      this.map = new AMap.Map("container", {
        resizeEnable: true,
        center: [116.355675, 39.870089],
        zoom: 11,
        mapStyle: "macaron",
      });
    },
    //判断浏览区是否支持canvas
    isSupportCanvas() {
      let elem = document.createElement("canvas");
      return !!(elem.getContext && elem.getContext("2d"));
    },
    createHeatMap() {
      if (!this.isSupportCanvas()) {
        return this.$message({
          message:
            "热力图仅对支持canvas的浏览器适用,您所使用的浏览器不能使用热力图功能,请换个浏览器试试。",
          type: "warning",
        });
      }
      let that = this;
      this.map.plugin(["AMap.Heatmap"], function () {
        //初始化heatmap对象
        that.heatmap = new AMap.Heatmap(that.map, {
          radius: 25, //给定半径
          opacity: [0, 0.8],
        });
        that.heatmap.setDataSet({
          data: heatmapData,
          max: 100,
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.shubox {
  box-sizing: border-box;
  width: 1730px;
  height: 840px;
  margin: 0;
  margin-left: 193px;
  margin-top: 0px;
  padding: 30px;
  background-color: #f8fafc;
  display: flex;
  position: absolute;
  justify-content: space-between;
  flex-wrap: wrap;
}
.echatitem {
  width: 818px;
  height: 370px;
  background-color: #fff;
  padding: 28px;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
}
.reechatitem {
  width: 100%;
  height: 100%;
}
.echatit {
  color: #4d5b80;
  font-size: 16px;
  // margin-bottom: 16px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  justify-content: flex-end;
}
#container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 90%;
  box-sizing: border-box;
}
// #container:hover {
//   transform: scale(1.05);
// }
.rebox {
  width: 180px;
  height: 100px;
  box-sizing: border-box;
  border-radius: 20px;
  position: absolute;
  bottom: 30px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.close {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.rebtn {
  border: 1px solid #2c68ff;
  color: #000;
  font-size: 20px;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.rebtn:hover {
  background-color: #2c68ff;
  color: #fff;
}
</style>